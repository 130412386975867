@import '~vuetify/src/styles/styles.sass';





























































































































































































































































































































































































































































.v-app-bar.v-app-bar--fixed {
  z-index: 50;
}
// @import '@design';
