@import '~vuetify/src/styles/styles.sass';
















































































// @import '@design';
.pointer:hover {
  cursor: pointer;
}
